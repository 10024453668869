import art from './art.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='App-text'>
          <b>WIP</b> Tecnologia
          <p className='App-description'>Em breve um site completo em soluções em tecnologia</p>
          <p className='App-description'>
            wiptecnologia.tech@gmail.com
          </p>
        </div>
        <img src={art} className="App-logo" alt="logo" />
        
      </header>
    </div>
  );
}

export default App;
